<template>
    <div v-if="state.showBox" class="notification-box__overlay"></div>
    <transition name="slide-right">
        <div v-if="state.showBox" ref="boxRef" class="notification-box">
            <div class="notification-box__header">
                <h3 class="notification-box__title">Notificações</h3>
                <a v-if="hasNotifications" class="notification-box__clean-all" @click.prevent="cleanAll">Limpar
                    todas</a>
            </div>
            <div class="notification-box__content">
                <notification-list v-if="hasNotifications"/>
                <div v-else class="notification-box__empty">
                    <img src="@/assets/images/illustrations/notifications-empty.png"
                        class="notification-box__empty-img" />
                    <p>Você não possui notificações</p>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import { reactive, onMounted, ref, computed } from "vue";
import { eventBus } from "@/services/eventBusService";
import { onClickOutside } from "@vueuse/core";
import { readAllNotifications, cleanAllNotifications } from "../../services/notificationService";
import { showErrorToast, showSuccessToast } from "@/services/toastService";
import { useNotificationStore } from "@/store/notificationData";
import NotificationList from '../NotificationList/NotificationList.vue'

export default {
    components: { NotificationList },
    setup() {
        const notificationStore = useNotificationStore();
        const state = reactive({
            showBox: false,
        })

        const hasNotifications = computed({
            get() {
                return notificationStore.getHasNotifications
            }
        })

        onMounted(() => {
            eventBus.on('toggleNotificationBox', showBox)
        })

        const boxRef = ref(null);
        onClickOutside(boxRef, () => close(), { ignore: ['.navbar__notifications'] });

        const showBox = () => {
            if (state.showBox) {
                readAll();
            }

            state.showBox = !state.showBox;
        }

        const close = () => {
            if (!state.showBox) {
                return;
            }

            readAll();

            state.showBox = false;
        }

        const readAll = () => {
            if (notificationStore.getUnreadNotificationsCount) {
                readAllNotifications();
            }
        }

        const cleanAll = () => {
            cleanAllNotifications().then(onClearSuccess).catch(onClearError)
        }

        const onClearSuccess = () => {
            notificationStore.cleanAllNotifications();
            showSuccessToast('Notificações excluídas com sucesso!')
        }

        const onClearError = () => {
            showErrorToast('Ocorreu um erro ao limpar as notificações. Tente novamente.')
        }

        return {
            state,
            boxRef,
            hasNotifications,
            cleanAll
        }
    }
}
</script>

<style lang="scss" src="./NotificationBox.scss" />
