import requestService from '@services/requestService'

const PREFERENCES_URL = '/account/preferences'

export function getPreferences() {
    return requestService.get(PREFERENCES_URL);
}

export function savePreferences(params) {
    return requestService.post(PREFERENCES_URL, params);
}

export function unregisterMarketing(userHash) {
    return requestService.post(`${PREFERENCES_URL}/unregister-email/${userHash}`);
}
