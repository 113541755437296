import { defineStore } from 'pinia';

export const useNotificationStore = defineStore({
    id: 'notificationStore',
    state: () => ({
        notifications: [],
        userToken: null
    }),
    getters: {
        getNotifications: (state) => {
            return state.notifications
        },
        getUnreadNotificationsCount: (state) => {
            const unreadNotifications = state.notifications?.filter(notification => !notification.wasRead)
            return unreadNotifications?.length;
        },
        getToken: (state) => {
            return state.userToken;
        },
        getHasNotifications: (state) => {
            return !!state.notifications?.length;
        }
    },
    actions: {
        setNotifications(data) {
            this.notifications = data;
        },
        cleanAllNotifications() {
            this.notifications = [];
        },
        setUserToken(token) {
            this.userToken = token;
        }
    }
})
