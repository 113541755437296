<template>
    <Modal class="change-transaction-situation-modal" :title="title" @close="onClose" @cancel="onCancel"
        :fetch-action="updateSituation" @fetch-success="onUpdateTransactionSituationSuccess"
        @fetch-error="onUpdateTransactionSituationError" confirm-label="Confirmar" :esc-to-close="false"
        :click-to-close="false">
        <template #default>
            <form @submit.prevent>
                <div class="container change-transaction-situation-modal__content">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="row">
                                <p class="change-transaction-situation-modal__description">{{ description }}</p>
                            </div>
                            <div v-if="showDateField" class="row">
                                <div class="form-group date-select">
                                    <label for="data-input">{{ dateInputLabel }}</label>
                                    <datepicker v-model="state.paidDate" :value="state.paidDate" name="data-input"
                                        required />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </template>
    </Modal>
</template>

<script>
import { computed, reactive } from 'vue';
import { TRANSACTION_TYPES } from '../../constants/transactionConstants';
import { updateTransactionSituation } from '../../services/transactionsService';
import { showSuccessToast, showErrorToast } from "@/services/toastService";
import { getCurrentDate } from "@/modules/common/services/date/dateService";
import Modal from "@/components/Modal/Modal.vue";
import Datepicker from "@/modules/common/components/Datepicker/Datepicker.vue";

export default {
    components: { Modal, Datepicker },
    props: {
        type: [String, Number],
        id: String
    },
    setup(props, { emit }) {
        const state = reactive({
            paidDate: getCurrentDate()
        })

        const showDateField = computed(() => {
            return props.type !== TRANSACTION_TYPES.TRANSFER.code
        })

        const title = computed(() => {
            if (props.type === TRANSACTION_TYPES.TRANSFER.code) {
                return `Marcar como concluída`
            }

            const label = props.type === TRANSACTION_TYPES.REVENUE.code ? 'recebida' : 'paga'
            return `Marcar como ${label}`
        })

        const description = computed(() => {
            if (props.type === TRANSACTION_TYPES.TRANSFER.code) {
                return `Deseja atualizar a transferência para concluída?`
            }

            const label = props.type === TRANSACTION_TYPES.REVENUE.code ? 'recebida' : 'paga'
            return `Deseja atualizar a transação para ${label}?`
        })

        const dateInputLabel = computed(() => {
            if (props.type === TRANSACTION_TYPES.TRANSFER.code) {
                return `Data de conclusão`
            }

            const label = props.type === TRANSACTION_TYPES.REVENUE.code ? 'recebimento' : 'pagamento'
            return `Data de ${label}`
        })

        const updateSituation = () => {
            const params = {
                id: props.id,
                status: true,
                paidDate: state.paidDate
            }

            return updateTransactionSituation(params);
        }

        const onUpdateTransactionSituationSuccess = () => {
            showSuccessToast('Transação atualizada com sucesso!')
            emit('success');
            onClose();
        }

        const onUpdateTransactionSituationError = () => {
            showErrorToast('Ocorreu um erro ao atualizar a transação. Tente novamente.');
        }

        const onClose = () => {
            emit("close");
        };

        const onCancel = () => {
            emit("cancel");
        };

        return {
            state,
            title,
            description,
            dateInputLabel,
            showDateField,
            onClose,
            onCancel,
            updateSituation,
            onUpdateTransactionSituationSuccess,
            onUpdateTransactionSituationError
        };
    },
};
</script>

<style lang="scss" src="./ChangeTransactionSituationModal.scss" />
