<template>
    <div class="planning-resume-card" :class="{ 'planning-resume-card--selected': isActive }">
        <h3 class="planning-resume-card__title">
            {{ planningData.name }}
        </h3>
        <div class="planning-resume-card__budget">
            <toggle-currency-visibility :value="planningData.value" /> <small>planejado</small>
        </div>
        <div class="planning-resume-card__budget">
            <toggle-currency-visibility :value="availableValue" /> <small>disponível</small>
        </div>
        <div class="planning-resume-card__period">
            {{ formatDate(planningData.startDate, planningData.endDate) }}
        </div>
    </div>
</template>

<script>
import { computed } from 'vue';
import { formatCurrency } from '@/services/currencyService';
import { formatStartAndEndDate } from '@/services/formatService';
import ToggleCurrencyVisibility from '@/modules/common/components/ToggleCurrencyVisibility/ToggleCurrencyVisibility.vue';

export default {
    components: { ToggleCurrencyVisibility },
    props: {
        planningData: {
            type: Object,
            default: () => { }
        },
        isActive: Boolean
    },
    setup(props) {
        const toCurrency = (value) => {
            return formatCurrency(value)
        }

        const formatDate = (startDate, endDate) => {
            if (!startDate || !endDate) {
                return;
            }

            return formatStartAndEndDate(new Date(startDate), new Date(endDate));
        }

        const savingsValue = computed({
            get() {
                const { value, savingPercent } = props.planningData
                return value && savingPercent ? (value * savingPercent) / 100 : 0
            }
        })

        const availableValue = computed({
            get() {
                const { value, currentValue } = props.planningData
                const current = currentValue || 0
                const savings = savingsValue.value || 0
                return value - current - savings
            }
        })

        return {
            formatDate,
            toCurrency,
            availableValue
        }
    }
}
</script>

<style lang="scss" src="./PlanningResumeCard.scss" />
