import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { saveNotificationUserToken, getNotifications } from "@/modules/dashboard/services/notificationService";

const firebaseConfig = {
    apiKey: "AIzaSyAXPc-2evY0ZO1liFKdL3hj4qS5_nDPlrk",
    authDomain: "calc-riquezas-notifications.firebaseapp.com",
    projectId: "calc-riquezas-notifications",
    storageBucket: "calc-riquezas-notifications.appspot.com",
    messagingSenderId: "867906156541",
    appId: "1:867906156541:web:f2a072ce1d834e3ac38b94"
};

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

export async function requestPushNotificationPermission() {
    try {
        const permission = await Notification.requestPermission();
        if (permission === 'granted') {
            const token = await getToken(messaging, { vapidKey: 'BLJV2xgfr003A8vajTX2ax5Dz6SMO1Qkbp8bOgbelxbVoTIJP1EyDMqIg-4c-_e4X__4xVz3RX4Npogu3obufZ8' });

            saveNotificationUserToken(token);

            return token;
        } else {
            return null;
        }
    } catch (e) {
        return null;
    }
}

onMessage(messaging, (payload) => {
    if (payload.notification) {
        const notificationTitle = payload.notification.title;
        const notificationOptions = {
            body: payload.notification.body,
        };
        
        getNotifications();

        new Notification(notificationTitle, notificationOptions);
    }
});

export { messaging, getToken, onMessage };