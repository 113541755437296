<template>
  <div class="financial">
    <page-header title="Financeiro" sticky>
      <transactions-page-filters />
      <dropdown-button id="add-transaction" class="financial__actions" label="Nova" :icon-label="btnAddIcon" primary>
        <template #options>
          <dropdown-item label="Receita" @click="createTransaction(state.revenueType)" />
          <dropdown-item label="Despesa" @click="createTransaction(state.expenseType)" />
          <dropdown-item label="Transferência" @click="createTransaction(state.transferType)" />
        </template>
      </dropdown-button>
    </page-header>
    <div class="financial__content">
      <box-page title="Balanço do período" id="finance-balance">
        <template #title>
          <FiltersBadgeList id="current-filters" />
        </template>
        <cards-resume :show-balance-button="false" />
      </box-page>
      <div class="financial__row">
        <box-page title="Receitas por categoria" id="income-categories-chart">
          <template #title>
            <FiltersBadgeList />
          </template>
          <totals-categories-chart :type="state.revenueType" />
        </box-page>
        <box-page title="Despesas por categoria" id="expenses-categories-chart">
          <template #title>
            <FiltersBadgeList />
          </template>
          <totals-categories-chart :type="state.expenseType" />
        </box-page>
      </div>
      <box-page title="Resumo das transações" id="transactions-list">
        <template #title>
          <FiltersBadgeList />
        </template>
        <div class="financial__transactions-list">
          <transactions-table />
        </div>
      </box-page>
    </div>
  </div>
</template>

<script>
import { reactive, computed, onBeforeMount } from 'vue';
import { useModal } from "vue-final-modal";
import { TRANSACTION_TYPES } from '../constants/transactionConstants';
import { eventBus } from "@/services/eventBusService";
import { isMobile } from "@/services/deviceService";
import { useTransactionsFiltersStore } from "@/store/transactionsFilters";
import BoxPage from "@/components/BoxPage/BoxPage.vue";
import PageHeader from "@/components/PageHeader/PageHeader.vue";
import DropdownButton from "@/components/DropdownButton/DropdownButton.vue";
import DropdownItem from "@/components/DropdownButton/DropdownItem.vue";
import TransactionsTable from "../components/TransactionsTable/TransactionsTable.vue";
import CardsResume from "../components/CardsResume/CardsResume.vue";
import CreateTransactionModal from '../components/CreateTransactionModal/CreateTransactionModal.vue';
import TransactionsPageFilters from '../components/TransactionsPageFilters/TransactionsPageFilters.vue';
import FiltersBadgeList from '../components/FiltersBadgeList/FiltersBadgeList.vue';
import TotalsCategoriesChart from '../components/TotalsCategoriesChart/TotalsCategoriesChart.vue';
import CreateTransferModal from '../components/CreateTransferModal/CreateTransferModal.vue';

export default {
  components: {
    BoxPage,
    PageHeader,
    TransactionsTable,
    CardsResume,
    DropdownButton,
    DropdownItem,
    TransactionsPageFilters,
    FiltersBadgeList,
    TotalsCategoriesChart
  },
  setup() {
    const filtersStore = useTransactionsFiltersStore();

    onBeforeMount(() => {
      filtersStore.resetFilters();
    })

    const state = reactive({
      transactionType: TRANSACTION_TYPES.REVENUE.code,
      revenueType: TRANSACTION_TYPES.REVENUE.code,
      expenseType: TRANSACTION_TYPES.EXPENSE.code,
      transferType: TRANSACTION_TYPES.TRANSFER.code,
      filters: {
        StartDate: null,
        EndDate: null,
      }
    })

    const modalTitle = computed({
      get() {
        return state.transactionType === TRANSACTION_TYPES.REVENUE.code ? 'Nova receita' : 'Nova despesa'
      }
    })

    const btnAddIcon = computed({
      get() {
        return isMobile() ? 'fa-plus' : null
      }
    })

    const createTransaction = (type) => {
      state.transactionType = type

      if (type === TRANSACTION_TYPES.TRANSFER.code) {
        openTransferModal();
        return;
      }

      openTransactionModal()
    }

    const { open: openTransactionModal, close: closeTransactionModal } = useModal({
      component: CreateTransactionModal,
      attrs: {
        title: modalTitle,
        type: computed(() => state.transactionType),
        onClose() {
          closeTransactionModal()
        },
        onConfirm() {
          closeTransactionModal()
        },
        onCancel() {
          closeTransactionModal()
        },
        onSuccess() {
          eventBus.emit('reloadData')
        }
      }
    });

    const { open: openTransferModal, close: closeTransferModal } = useModal({
      component: CreateTransferModal,
      attrs: {
        onClose() {
          closeTransferModal()
        },
        onConfirm() {
          closeTransferModal()
        },
        onCancel() {
          closeTransferModal()
        },
        onSuccess() {
          eventBus.emit('reloadData');
        }
      }
    });

    return {
      state,
      btnAddIcon,
      createTransaction
    }
  }
};
</script>

<style lang="scss" src="./styles/FinancialView.scss" />
