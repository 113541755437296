<template>
  <div class="transaction-type-badge">
    <badge :value="getBadgeProps.text" :type="getBadgeProps.type" />
  </div>
</template>

<script>
import { computed } from 'vue';
import { TRANSACTION_TYPES } from '../../constants/transactionConstants';
import Badge from '@/components/Badge/Badge.vue';

export default {
  components: { Badge },
  props: {
    type: Number,
  },
  setup(props) {
    const getBadgeProps = computed({
      get() {
        if (props.type === TRANSACTION_TYPES.REVENUE.code) {
          return {
            text: TRANSACTION_TYPES.REVENUE.name,
            type: 'success'
          }
        }

        if (props.type === TRANSACTION_TYPES.EXPENSE.code) {
          return {
            text: TRANSACTION_TYPES.EXPENSE.name,
            type: 'warning'
          }
        }

        return {
          text: TRANSACTION_TYPES.TRANSFER.name,
          type: ''
        }
      }
    })

    return {
      getBadgeProps
    }
  }
}
</script>
