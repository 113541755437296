<template>
  <div class="transaction-situation-badge">
    <badge :value="getBadgeProps.text" :type="getBadgeProps.type" />
  </div>
</template>

<script>
import { computed } from 'vue';
import Badge from '@/components/Badge/Badge.vue';

export default {
  components: { Badge },
  props: {
    type: Number
  },
  setup(props) {
    const getBadgeProps = computed({
      get() {
        return props.type ? {
          text: 'Saída',
          type: 'warning'
        } : {
          text: 'Entrada',
          type: 'success'
        }
      }
    })

    return {
      getBadgeProps
    }
  }
}
</script>
