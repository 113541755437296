import { createRouter, createWebHashHistory } from "vue-router";
import { checkAuthentication, checkPermission } from './beforeAcess';
import LoginView from "@/modules/user/views/LoginView";
import UserSettingsView from "@/modules/user/views/UserSettingsView";
import ContactView from "@/modules/user/views/ContactView";
import MainView from '../views/MainView'
import DashboardView from "@/modules/dashboard/views/DashboardView";
import FinancialView from '@/modules/financial/views/FinancialView';
import RevenuesView from '@/modules/financial/views/RevenuesView';
import ExpensesView from '@/modules/financial/views/ExpensesView';
import TransfersView from '@/modules/financial/views/TransfersView';
import CategoryListView from '@/modules/financial/views/CategoryListView';
import ImportTransactionsView from '@/modules/financial/views/ImportTransactionsView';
import RecoveryPasswordView from "@/modules/user/views/RecoveryPasswordView";
import PlansDetailsView from "@/modules/checkout/views/PlansDetailsView.vue";
import UserPlanView from "@/modules/user/views/UserPlanView.vue";
import UserPreferencesView from "@/modules/user/views/UserPreferencesView.vue";
import CheckoutView from "@/modules/checkout/views/CheckoutView.vue";
import GoalsView from '@/modules/financial/views/GoalsView';
import EditGoalView from '@/modules/financial/views/EditGoalView';
import PlanningView from "@/modules/financial/views/PlanningView.vue";
import CreatePlanningView from "@/modules/financial/views/CreatePlanningView.vue";
import UnregisterMarketingView from "@/modules/user/views/UnregisterMarketingView.vue";

const routes = [
  {
    path: '/',
    name: 'HomePage',
    component: MainView,
    meta: { requiresAuth: true },
    children: [
      {
        path: '/',
        name: 'HomePage',
        component: DashboardView
      },
      {
        path: '/user/account-settings',
        name: 'UserSettingsView',
        component: UserSettingsView
      },
      {
        path: '/user/my-plan',
        name: 'UserPlanView',
        component: UserPlanView
      },
      {
        path: '/user/preferences',
        name: 'UserPreferencesView',
        component: UserPreferencesView
      },
      {
        path: '/support',
        name: 'ContactView',
        component: ContactView
      },
      {
        path: '/finance',
        name: 'FinancialView',
        component: FinancialView
      },
      {
        path: '/finance/revenues',
        name: 'RevenuesView',
        component: RevenuesView
      },
      {
        path: '/finance/expenses',
        name: 'ExpensesView',
        component: ExpensesView
      },
      {
        path: '/finance/transfers',
        name: 'TransfersView',
        component: TransfersView
      },
      {
        path: '/finance/categories',
        name: 'CategoryListView',
        component: CategoryListView
      },
      {
        path: '/finance/import',
        name: 'ImportTransactionsView',
        component: ImportTransactionsView
      },
      {
        path: '/finance/goals',
        name: 'GoalsView',
        component: GoalsView,
      },
      {
        path: '/finance/goals/:id',
        name: 'EditGoalView',
        component: EditGoalView
      },
      {
        path: '/plans',
        name: 'PlansDetailsView',
        component: PlansDetailsView
      },
      {
        path: '/checkout',
        name: 'CheckoutView',
        component: CheckoutView,
      },
      {
        path: '/finance/plannings',
        name: 'PlanningView',
        component: PlanningView,
      },
      {
        path: '/finance/plannings/:id',
        name: 'CreatePlanningView',
        component: CreatePlanningView,
      }
    ]
  },
  {
    path: "/login",
    name: "login",
    component: LoginView,
  },
  {
    path: "/recovery-password",
    name: "RecoveryPassword",
    component: RecoveryPasswordView,
  },
  {
    path: "/unregister-email/:hash",
    name: "UnregisterMarketingView",
    component: UnregisterMarketingView,
  }
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach(async (to, from, next) => {
  const isAuthenticated = await checkAuthentication()
  if (to.meta.requiresAuth && !isAuthenticated) {
    next('/login');
  } else if (to.meta.requiredRole && !checkPermission(to.meta.requiredRole)) {
    next('/login');
  } else {
    next();
  }
});

export default router;
