<template>
  <loader v-if="state.isLoading" />
  <div v-else class="cards-resume cards-resume-transfers">
    <CardResume title="Transferências pendentes" icon="fa-arrow-right-arrow-left" :value="state.transactionsData.pendingTransfers">
      <template #default>
        <p class="cards-resume__additional-info">Entradas: <toggle-currency-visibility :value="state.transactionsData.pendingInTransfers" /></p>
        <p class="cards-resume__additional-info">Saídas: <toggle-currency-visibility :value="state.transactionsData.pendingOutTransfers" /></p>
      </template>
    </CardResume>
    <CardResume title="Transferências concluídas" icon="fa-arrow-right-arrow-left" :value="state.transactionsData.transfers">
      <template #default>
        <p class="cards-resume__additional-info">Entradas: <toggle-currency-visibility :value="state.transactionsData.inTransfers" /></p>
        <p class="cards-resume__additional-info">Saídas: <toggle-currency-visibility :value="state.transactionsData.outTransfers" /></p>
      </template>
    </CardResume>
    <CardResume title="Total do período" icon="fa-arrow-right-arrow-left" :value="state.transactionsData.monthlyBalance">
      <template #default>
        <p class="cards-resume__additional-info">Entradas: <toggle-currency-visibility :value="state.transactionsData.monthlyBalanceIn" /></p>
        <p class="cards-resume__additional-info">Saídas: <toggle-currency-visibility :value="state.transactionsData.monthlyBalanceOut" /></p>
      </template>
    </CardResume>
  </div>
</template>

<script>
import { onMounted, reactive, watch, onUnmounted } from 'vue';
import { TRANSACTION_TYPES } from '../../constants/transactionConstants';
import { useTransactionsFiltersStore } from "@/store/transactionsFilters";
import { getTransactionsSimpleBalance } from "../../services/transactionsService";
import { eventBus } from "@/services/eventBusService";
import CardResume from "../CardResume/CardResume.vue";
import Loader from '@/components/Loader/Loader.vue';
import ToggleCurrencyVisibility from '@/modules/common/components/ToggleCurrencyVisibility/ToggleCurrencyVisibility.vue';

export default {
  components: { CardResume, Loader, ToggleCurrencyVisibility },
  setup() {
    const filtersStore = useTransactionsFiltersStore();

    const state = reactive({
      isLoading: false,
      transactionsData: {
        pendingTransfers: null,
        transfers: null,
        monthlyBalance: null,
        pendingInTransfers: null,
        pendingOutTransfers: null,
        inTransfers: null,
        outTransfers: null,
        monthlyBalanceIn: null,
        monthlyBalanceOut: null,
      }
    })

    const reloadDataHandler = () => {
      fetchData()
    }

    onMounted(() => {
      eventBus.on('reloadData', reloadDataHandler)
      fetchData()
    });

    onUnmounted(() => {
      eventBus.off('reloadData', reloadDataHandler)
    })

    const fetchData = () => {
      const filters = {
        ...filtersStore.getTransactionsFilters,
        Type: TRANSACTION_TYPES.TRANSFER.code
      };

      state.isLoading = true;
      getTransactionsSimpleBalance(filters)
        .then(fetchDataSuccess)
        .catch(fetchDataError)
    }

    const fetchDataSuccess = (balance) => {
      state.transactionsData = { ...balance }
      state.isLoading = false;
    }

    const fetchDataError = () => {
      state.isLoading = false;
    }

    watch(() => filtersStore.getTransactionsFilters, () => fetchData(), { deep: true });
    
    return {
      state
    }
  }
};
</script>

