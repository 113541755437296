<template>
    <div class="range-percent form-group" :class="{ 'range-percent--has-label': label }">
        <label v-if="label" class="range-percent__label">{{ label }}</label>
        <span class="range-percent__root">
            <span class="range-percent__rail"></span>
            <span class="range-percent__track" :style="{ width: `${percentageValue}%` }"></span>
            <input type="hidden" v-model="state.localModel">
            <span class="range-percent__thumb" tabindex="0" role="slider" :aria-valuemax="max" :aria-valuemin="min"
                :aria-valuenow="state.localModel" :style="{ left: `${percentageValue}%` }" @mousedown="startDragging"
                @keydown="handleKeydown">
                <span class="range-percent__badge">{{ state.localModel }}%</span>
            </span>
        </span>
    </div>
</template>

<script>
import { reactive, computed, onMounted, onBeforeUnmount, watch } from 'vue';

export default {
    props: {
        label: String,
        min: {
            type: Number,
            default: 0
        },
        max: {
            type: Number,
            default: 100
        },
        value: [String, Number]
    },
    setup(props, { emit }) {
        const state = reactive({
            localModel: props.value || props.min,
            isDragging: false
        });

        const percentageValue = computed(() => {
            return ((state.localModel - props.min) / (props.max - props.min)) * 100;
        });

        const handleKeydown = (event) => {
            if (event.key === 'ArrowRight' && state.localModel < props.max) {
                state.localModel += 1;
            } else if (event.key === 'ArrowLeft' && state.localModel > props.min) {
                state.localModel -= 1;
            }
        };

        const startDragging = (event) => {
            event.preventDefault();
            state.isDragging = true;
        };

        const handleMouseMove = (event) => {
            if (state.isDragging) {
                const slider = document.querySelector('.range-percent__root');
                if (slider) {
                    const rect = slider.getBoundingClientRect();
                    const newValue = Math.min(
                        Math.max(
                            ((event.clientX - rect.left) / rect.width) * (props.max - props.min) + props.min,
                            props.min
                        ),
                        props.max
                    );
                    state.localModel = Math.round(newValue);
                }
            }
        };

        watch(() => state.localModel, (newValue) => { emit('update:modelValue', newValue)});

        const stopDragging = () => {
            state.isDragging = false;
        };

        onMounted(() => {
            window.addEventListener('mousemove', handleMouseMove);
            window.addEventListener('mouseup', stopDragging);
        });

        onBeforeUnmount(() => {
            window.removeEventListener('mousemove', handleMouseMove);
            window.removeEventListener('mouseup', stopDragging);
        });

        return {
            state,
            percentageValue,
            handleKeydown,
            startDragging
        };
    }
};
</script>

<style lang="scss" src="./RangePercent.scss" />
