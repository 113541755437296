<template>
  <div class="planning-details">
    <page-header :title="state.planning.name">
      <button class="btn button-primary small" v-tooltip="'Editar planejamento'" @click="editPlanning">Editar</button>
      <button class="btn button-primary--alert small" v-tooltip="'Excluir planejamento'"
        @click="openDeletionConfirmModal">Excluir</button>
    </page-header>
    <div class="planning-details__box planning-details__budget">
      <planning-budget-goal :budget-data="state.planning" />
    </div>
    <div class="planning-details__list">
      <planning-categories-list :allow-add="false" :savings-value="savingsValue" :budgets-data="state.planning.budgets" />
    </div>
  </div>
</template>

<script>
import { reactive, watch, computed } from 'vue';
import { useRouter } from 'vue-router';
import { useModal } from 'vue-final-modal';
import PageHeader from '@/components/PageHeader/PageHeader.vue';
import PlanningCategoriesList from '../PlanningCategoriesList/PlanningCategoriesList.vue';
import PlanningBudgetGoal from '../PlanningBudgetGoal/PlanningBudgetGoal.vue';
import ConfirmationModal from '@/modules/common/components/ConfirmationModal/ConfirmationModal.vue';

export default {
  components: { PageHeader, PlanningCategoriesList, PlanningBudgetGoal },
  props: {
    planningData: Object
  },
  setup(props, { emit }) {
    const router = useRouter();
    const state = reactive({
      planning: {
        ...props.planningData
      }
    })

    watch(() => props.planningData,
      (value) => state.planning = value,
      { deep: true })

    const savingsValue = computed({
      get() {
        const { value, savingPercent } = state.planning
        const totalValue = value && savingPercent ? (value * savingPercent) / 100 : 0
        return totalValue
      }
    })

    const editPlanning = () => {
      router.push(`/finance/plannings/${state.planning.id}`)
    }

    const onDeletePlanning = () => {
      emit('delete-planning', state.planning.id)
    }

    const { open: openDeletionConfirmModal, close: closeDeletionConfirmModal } = useModal({
      component: ConfirmationModal,
      attrs: {
        buttonType: 'alert',
        title: 'Excluir planejamento',
        description: `Tem certeza de que deseja exluir o planejamento ${state.planning.name}?`,
        confirmLabel: 'Excluir',
        onClose() {
          closeDeletionConfirmModal()
        },
        onConfirmAction() {
          onDeletePlanning()
          closeDeletionConfirmModal()
        },
        onCancel() {
          closeDeletionConfirmModal()
        },
      }
    });


    return {
      state,
      savingsValue,
      editPlanning,
      openDeletionConfirmModal
    }
  }
}
</script>

<style lang="sc,
    PlanningBudgetGoalss" src="./PlanningDetails.scss" />