<template>
    <div class="notification" :class="{ 'notification--unread': !notificationData.wasRead }">
        <a href=" " class="notification__link" :class="{ 'notification__link--no-link': !notificationData.link }"
            @click.prevent="openUrl">
            <div class="notification__content">
                <template v-if="notificationData.image">
                    <div class="notification__image" :style="`color: ${notificationData.color}`">
                        <fa-icon v-if="isIcon" class="notification__icon" :icon="formattedIcon" />
                        <img v-else class="notification__icon" :src="notificationData.image"
                            :alt="notificationData.title">
                    </div>
                </template>

                <div class="notification__data">
                    <p class="notification__title"><b> {{ notificationData.title }}</b></p>
                    <p class="notification__description">{{ notificationData.description }}</p>
                </div>
            </div>
            <div v-if="notificationData.link" class="notification__link-icon">
                <fa-icon icon="fa-solid fa-chevron-right" />
            </div>
        </a>
    </div>
</template>

<script>
import { computed } from 'vue'
import { useRouter } from 'vue-router';

export default {
    props: {
        notificationData: Object
    },
    setup(props) {
        const router = useRouter();
        const isIcon = computed({
            get() {
                return props.notificationData?.image.startsWith('fa-')
            }
        });

        const formattedIcon = computed({
            get() {
                return props.notificationData?.image.startsWith('fa-') ? `fa-solid ${props.notificationData.image}` : ''
            }
        });

        const openUrl = () => {
            const url = props.notificationData?.link;

            if (!url?.trim()) {
                return;
            }

            if (url.startsWith('https') || url.startsWith('www')) {
                window.open(url, '_blank');
            } else {
                router.push(url);
            }
        }

        return {
            isIcon,
            formattedIcon,
            openUrl
        }
    }
}
</script>

<style lang="scss" src="./Notification.scss" />
