<template>
    {{ showValue ? toCurrency(value) : 'R$ ******' }}
</template>

<script>
import { computed } from 'vue';
import { formatCurrency } from '@/services/currencyService';
import { useUserDataStore } from "@/store/userData";

export default {
    props: {
        value: Number
    },
    setup() {
        const userStore = useUserDataStore();

        const showValue = computed(() => userStore.getShouldShowCurrencyValues)

        const toCurrency = (value) => {
            if (!value) {
                return 'R$ 0,00'
            }

            return formatCurrency(value)
        }

        return {
            showValue,
            toCurrency
        }
    }
}
</script>
