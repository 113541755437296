import { defineStore } from 'pinia';

export const useUserDataStore = defineStore({
    id: 'userData',
    state: () => ({
        userData: {
            email: null,
            role: 'FREE',
            name: null
        },
        authData: {
            token: '',
            expiration: '',
            refreshToken: '',
            refreshExpiration: '',
            isAuthenticated: false
        },
        userPreferences: {
            showCurrencyValues: false
        }
    }),
    getters: {
        getUserToken: (state) => {
            return state.authData.token;
        },
        getUserData: (state) => {
            return state.userData;
        },
        getUserRole: (state) => {
            return state.userData.role;
        },
        getRefreshToken: (state) => {
            return state.authData.refreshToken
        },
        isPremiumUser: (state) => {
            return state.userData?.role?.toUpperCase() === 'PREMIUM';
        },
        getUserEmail: (state) => {
            return state.userData.email;
        },
        getUserName: (state) => {
            return state.userData.name;
        },
        getIsAuthenticated: (state) => {
            return state.authData.isAuthenticated;
        },
        getShouldShowCurrencyValues: (state) => {
            return state.userPreferences.showCurrencyValues;
        }
    },
    actions: {
        setUserData(data) {
            this.userData = data;
        },
        setTokenData(data) {
            const { accessToken, expiration, refreshToken, refreshExpiration } = data

            this.authData.token = accessToken
            this.authData.expiration = expiration
            this.authData.refreshToken = refreshToken;
            this.authData.refreshExpiration = refreshExpiration;
            this.authData.isAuthenticated = true;
        },
        resetAuthData() {
            this.authData.token = '';
            this.authData.expiration = '';
            this.authData.refreshToken = '';
            this.authData.refreshExpiration = '';
            this.authData.isAuthenticated = false;
        },
        toggleUserShowCurrencyValues() {
            this.userPreferences.showCurrencyValues = !this.userPreferences.showCurrencyValues;
        },
        setPreferences(data) {
            this.userPreferences = {
                ...this.userPreferences,
                ...data
            };
        },
        setCurrencyVisibility(isVisible) {
            this.userPreferences.showCurrencyValues = isVisible;
        }
    }
})
