<template>
  <div class="app-container">
    <TopMenu @toggle-menu-mobile="toggleMenuMobile" />

    <div class="app-container__body">
      <side-menu class="app-container__side-menu" @close-menu="onCloseMenu"
        :class="{ 'show-mobile': state.showMenuMobile }" />
      <div class="app-container__content">
        <premium-overlay v-if="state.showPremiumOverlay" :title="state.premiumOverlayData.title"
          :description="state.premiumOverlayData.description" />
        <div class="app-container__content-wrapper">
          <router-view v-show="!state.showPremiumOverlay"></router-view>
        </div>
      </div>
      
      <notification-box />
    </div>
  </div>
</template>

<script>
import { onBeforeMount, onUnmounted, onMounted, reactive } from "vue";
import { useUserDataStore } from "@/store/userData";
import { getUserData } from "../modules/user/services/userService";
import { getUserTracersList } from "@/modules/common/services/tracer/tracerService";
import { getPreferences } from "@/modules/user/services/userPreferencesService";
import { getNotifications } from "@/modules/dashboard/services/notificationService";
import { eventBus } from "@/services/eventBusService";
import { requestPushNotificationPermission } from '@/plugins/firebase';
import PremiumOverlay from '@/modules/premium/components/PremiumOverlay/PremiumOverlay.vue';
import TopMenu from "../components/TopMenu/TopMenu.vue";
import SideMenu from "../components/SideMenu/SideMenu.vue";
import NotificationBox from '@/modules/dashboard/components/NotificationBox/NotificationBox.vue';

export default {
  components: { SideMenu, TopMenu, PremiumOverlay, NotificationBox },
  setup() {
    const userStore = useUserDataStore();

    const state = reactive({
      showMenuMobile: false,
      showPremiumOverlay: false,
      isPageHidden: false,
      premiumOverlayData: {
        title: null,
        description: null
      }
    });

    onBeforeMount(() => {
      handleUserData();
      getUserTracersList();
      getPreferences();
      getNotifications();
    });

    onMounted(() => {
      requestPushNotificationPermission();

      eventBus.on('showPremiumOverlay', showPremiumOverlay)
      eventBus.on('hidePremiumOverlay', hidePremiumOverlay)

      document.addEventListener("visibilitychange", handleVisibilityChange);
    });

    onUnmounted(() => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    });

    const showPremiumOverlay = (params) => {
      state.premiumOverlayData = {
        title: null,
        description: null,
      }

      if (params) {
        const { title, description } = params;

        state.premiumOverlayData = {
          title,
          description
        }
      }

      state.showPremiumOverlay = true;
    }

    const handleVisibilityChange = () => {
      if (document.hidden) {
        state.isPageHidden = true;
      } else if (state.isPageHidden) {
        getNotifications();
        state.isPageHidden = false;
      }
    };

    const hidePremiumOverlay = () => {
      state.showPremiumOverlay = false;
    }

    const handleUserData = () => {
      getUserData().then(getUserDataSuccess).catch(getUserDataError);
    };

    const getUserDataSuccess = (data) => {
      userStore.setUserData(data);
    };

    const getUserDataError = () => {
      // TODO: tratar erros
    };

    const toggleMenuMobile = () => {
      state.showMenuMobile = !state.showMenuMobile;
    };

    const onCloseMenu = () => {
      state.showMenuMobile = false;
    };

    return {
      state,
      onCloseMenu,
      toggleMenuMobile,
    };
  },
};
</script>

<style lang="scss" src="./MainView.scss" />
