<template>
    <transfers-list-mobile v-if="isMobileDevice" :type="type"/>
    <transfers-list v-else :type="type" hide-type/>
</template>

<script>
import { computed } from 'vue';
import { isMobile } from '@/services/deviceService';
import TransfersList from '../TransfersList/TransfersList.vue';
import TransfersListMobile from '../TransfersListMobile/TransfersListMobile.vue';
import { TRANSACTION_TYPES } from '../../constants/transactionConstants';

export default {
    components: { TransfersList, TransfersListMobile },
    setup() {
        const type = TRANSACTION_TYPES.TRANSFER.code;

        const isMobileDevice = computed({
            get() {
                return isMobile();
            }
        })

        return {
            type,
            isMobileDevice
        }
    }
}
</script>
