import requestService from '@services/requestService'

const NOTIFICATIONS_URL = '/notification'

export function getNotifications() {
    return requestService.get(NOTIFICATIONS_URL);
}

export function readAllNotifications() {
    return requestService.post(`${NOTIFICATIONS_URL}/read-all`);
}

export function cleanAllNotifications() {
    return requestService.post(`${NOTIFICATIONS_URL}/clean-all`);
}

export function saveNotificationUserToken(token) {
    return requestService.post(`${NOTIFICATIONS_URL}/save-token/${token}`);
}

export function deleteNotificationUserToken(token) {
    return requestService.post(`${NOTIFICATIONS_URL}/delete-token/${token}`);
}
