<template>
  <div class="page-header" :class="{ 'page-header--sticked': sticky, 'page-header--sticked-scrolled': sticky && state.isScrolled }">
    <h2 class="page-header__title">
      {{ title }}
      <button id="init-tour" v-if="state.hasTour" @click="clickStartTour"
        class="page-header__tour btn small button-outline" :class="{ 'page-header__tour--mobile': isMobileDevice }">
        {{ tourText }}
        <fa-icon v-if="isMobileDevice" icon="fa-solid fa-question" />
      </button>
    </h2>
    <div class="page-header__actions">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import { getTourData, initTour, verifyTourRoute } from '@/modules/financial/services/tourService';
import { isMobile } from '@/services/deviceService';
import { onMounted, reactive, computed, onBeforeUnmount } from 'vue';
import { useRouter } from 'vue-router';

export default {
  props: {
    title: String,
    sticky: Boolean
  },
  setup(props) {
    const router = useRouter();
    const state = reactive({
      hasTour: false,
      tourData: [],
      isScrolled: false
    })

    onMounted(() => {
      const currentRoute = router.currentRoute.value.path;
      state.hasTour = verifyTourRoute(currentRoute);
      state.tourData = getTourData(currentRoute);

      if (props.sticky) {
        const appContainer = document.querySelector('.app-container__content');
        appContainer.addEventListener('scroll', handleScroll);
      }
    })

    onBeforeUnmount(() => {
      if (props.sticky) {
        const appContainer = document.querySelector('.app-container__content');
        appContainer.removeEventListener('scroll', handleScroll);
      }
    });

    const handleScroll = (event) => {
      state.isScrolled = event.target.scrollTop > 20;
    };

    const isMobileDevice = computed({
      get() {
        return isMobile();
      }
    })

    const tourText = computed({
      get() {
        return isMobile() ? '' : 'Como funciona?';
      }
    })

    const clickStartTour = () => {
      const tour = initTour(state.tourData);
      tour.drive()
    }

    return {
      state,
      isMobileDevice,
      tourText,
      clickStartTour
    }
  }
};
</script>

<style lang="scss" src="./PageHeader.scss" />
