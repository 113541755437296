import * as userPreferencesResource from './userPreferencesResource';
import { useUserDataStore } from "@/store/userData";


function updateUserPreferences(preferences) {
    const userStore = useUserDataStore();
    userStore.setPreferences(preferences);
    userStore.setCurrencyVisibility(preferences?.account?.showCurrency);
}

export async function getPreferences() {
    const { data } = await userPreferencesResource.getPreferences();
    const preferences = data.item?.preferences;

    updateUserPreferences(preferences);

    return preferences;
}

export async function savePreferences(params) {
    const { data } = await userPreferencesResource.savePreferences(params);
    const preferences = data.item?.preferences;

    updateUserPreferences(preferences);

    return preferences;
}

export async function unregisterMarketing(userHash) {
    const { data } = await userPreferencesResource.unregisterMarketing(userHash);

    return data;
}