<template>
  <data-table :is-loading="state.isLoading" :columns="transactionsColumns" :data="state.transactionsData"
    @change-pagination="onChangePagination" hide-filters class="transactions-list" sorted-by="transactionDate">
    <template #row="{ row }">
      <td id="description">{{ row.description }}</td>
      <td id="recurrence">
        <fa-icon class="transactions-list__recurrence-icon" v-if="getRecurrencyIcon(row)" :icon="getRecurrencyIcon(row)"
          v-tooltip="getRecurrencyTooltip(row)" />
      </td>
      <td id="date"> {{ formatDate(row.transactionDate) }}</td>
      <td id="type" v-if="!hideType">
        <transaction-type-badge :type="row.type" />
      </td>
      <td id="status">
        <transaction-situation-badge :type="row.type" :situation="row.wasPaid" />
      </td>
      <td id="category">
        <transaction-category :category="row.category" />
      </td>
      <td id="value" :class="getTransactionClass(row.type)">
        <toggle-currency-visibility :value="Number(row.value)" />
      </td>
      <td>
        <DropdownButton label="Ações" size="sm">
          <template #options>
            <DropdownItem label="Editar" @click="editTransaction(row.id, row.type)" />
            <DropdownItem v-if="!row.wasPaid" :label="transactionSituationAction(row.type)"
              @click="editTransaction(row.id, row.type, true)" />
            <DropdownItem label="Excluir" @click="removeTransaction(row)" />
          </template>
        </DropdownButton>
      </td>
    </template>
  </data-table>
</template>

<script>
import { reactive, computed, onMounted, watch, onUnmounted } from "vue";
import { getRecurrenceIcon, getTransactions, getRecurrenceTooltip } from "../../services/transactionsService";
import { useModal } from "vue-final-modal";
import { useTransactionsFiltersStore } from "@/store/transactionsFilters";
import { TRANSACTION_TYPES, TRANSACTIONS_COLUMNS } from "../../constants/transactionConstants";
import { formatFullDate } from "@/services/formatService";
import { eventBus } from "@/services/eventBusService";
import DataTable from "@/components/DataTable/DataTable.vue";
import DropdownButton from "@/components/DropdownButton/DropdownButton.vue";
import DropdownItem from "@/components/DropdownButton/DropdownItem.vue";
import RemoveTransactionModal from "../RemoveTransactionModal/RemoveTransactionModal.vue";
import CreateTransactionModal from '../CreateTransactionModal/CreateTransactionModal.vue';
import TransactionSituationBadge from '../TransactionSituationBadge/TransactionSituationBadge.vue';
import TransactionCategory from '../TransactionCategory/TransactionCategory.vue';
import ToggleCurrencyVisibility from "@/modules/common/components/ToggleCurrencyVisibility/ToggleCurrencyVisibility.vue";
import ChangeTransactionSituationModal from '../ChangeTransactionSituationModal/ChangeTransactionSituationModal.vue';
import TransactionTypeBadge from '../TransactionTypeBadge/TransactionTypeBadge.vue';
import CreateTransferModal from '../CreateTransferModal/CreateTransferModal.vue';

export default {
  components: { DataTable, DropdownButton, DropdownItem, TransactionSituationBadge, TransactionCategory, ToggleCurrencyVisibility, TransactionTypeBadge },
  props: {
    type: Number,
    hideType: Boolean
  },
  setup(props) {
    const transactionsColumns = props.hideType ? TRANSACTIONS_COLUMNS.filter(x => x.key !== "type") : TRANSACTIONS_COLUMNS

    const filtersStore = useTransactionsFiltersStore();

    const state = reactive({
      transactionsData: {
        items: [],
        totalItems: 0,
      },
      isLoading: true,
      transactionToEdit: null,
      transactionToEditType: null,
      transactionToRemove: null,
      paginationFilters: {
        PageSize: 10,
        PageNumber: 1
      }
    });

    const getListTransactions = () => {
      const filters = {
        ...filtersStore.getTransactionsFilters,
        ...state.paginationFilters,
        Type: props.type
      };

      state.isLoading = true;
      return getTransactions(filters).then(getListTransactionsSuccess).catch(getListTransactionsError);
    };

    const getListTransactionsSuccess = (data) => {
      const { items, totalItems } = data;

      state.transactionsData = {
        items,
        totalItems
      };

      state.isLoading = false;
    }

    const getListTransactionsError = () => {
      state.isLoading = false;
    }

    const reloadDataHandler = () => {
      getListTransactions()
    }

    onMounted(() => {
      eventBus.on('reloadData', reloadDataHandler)
      getListTransactions();
    });

    onUnmounted(() => {
      eventBus.off('reloadData', reloadDataHandler)
    })

    watch(() => filtersStore.getTransactionsFilters, () => getListTransactions(), { deep: true });

    const getTransactionClass = (type) => {
      if (type === TRANSACTION_TYPES.EXPENSE.code) {
        return 'expense'
      }

      if (type === TRANSACTION_TYPES.REVENUE.code) {
        return 'revenue'
      }

      return 'secondary'
    };

    const getRecurrencyIcon = (transaction) => {
      return getRecurrenceIcon(transaction.recurrenceType)
    }

    const getRecurrencyTooltip = (transaction) => {
      return getRecurrenceTooltip(transaction)
    }

    const editTransaction = (id, type, editSituation) => {
      state.transactionToEdit = id;
      state.transactionToEditType = type;
      if (editSituation) {
        openChangeTransactionSituationModal()
      }
      else {
        openEditTransaction()
      }
    }

    const removeTransaction = (transaction) => {
      const { description, id, recurrenceType } = transaction;
      state.transactionToRemove = { description, id, recurrenceType }

      openRemoveTransactionModal()
    }

    const { open: openRemoveTransactionModal, close: closeRemoveTransactionModal } = useModal({
      component: RemoveTransactionModal,
      attrs: {
        transactionId: computed(() => state.transactionToRemove.id),
        nameTransaction: computed(() => state.transactionToRemove.description),
        recurrenceType: computed(() => state.transactionToRemove.recurrenceType),
        buttonType: 'alert',
        onClose() {
          closeRemoveTransactionModal()
        },
        onConfirm() {
          closeRemoveTransactionModal()
        },
        onCancel() {
          closeRemoveTransactionModal()
        },
        onSuccess() {
          eventBus.emit('reloadData')
        }
      }
    });

    const { open: openTransactionModal, close: closeTransactionModal } = useModal({
      component: CreateTransactionModal,
      attrs: {
        title: computed(() => state.transactionToEdit) ? 'Editar transação' : 'Adicionar transação',
        transactionId: computed(() => state.transactionToEdit),
        onClose() {
          closeTransactionModal()
        },
        onConfirm() {
          closeTransactionModal()
        },
        onCancel() {
          closeTransactionModal()
        },
        onSuccess() {
          eventBus.emit('reloadData')
        }
      }
    });

    const { open: openTransferModal, close: closeTransferModal } = useModal({
      component: CreateTransferModal,
      attrs: {
        transactionId: computed(() => state.transactionToEdit),
        onClose() {
          closeTransferModal()
        },
        onConfirm() {
          closeTransferModal()
        },
        onCancel() {
          closeTransferModal()
        },
        onSuccess() {
          eventBus.emit('reloadData');
        }
      }
    });

    const openEditTransaction = () => {
      if (state.transactionToEditType === TRANSACTION_TYPES.TRANSFER.code) {
        openTransferModal();
        return;
      }

      openTransactionModal()
    }

    const formatDate = (date) => {
      return formatFullDate(new Date(date))
    }

    const onChangePagination = (data) => {
      const { PageSize, PageNumber } = data;
      state.paginationFilters = {
        PageSize,
        PageNumber
      }

      getListTransactions();
    }

    const transactionSituationAction = (type) => {
      return type === TRANSACTION_TYPES.REVENUE.code ? 'Receber' : 'Pagar'
    }

    const { open: openChangeTransactionSituationModal, close: closeChangeTransactionSituationModal } = useModal({
      component: ChangeTransactionSituationModal,
      attrs: {
        id: computed(() => state.transactionToEdit),
        type: computed(() => state.transactionToEditType),
        onClose() {
          closeChangeTransactionSituationModal()
        },
        onSuccess() {
          eventBus.emit('reloadData');
          closeChangeTransactionSituationModal();
        },
        onCancel() {
          closeChangeTransactionSituationModal()
        }
      }
    });

    return {
      state,
      transactionsColumns,
      transactionSituationAction,
      getListTransactions,
      editTransaction,
      removeTransaction,
      getTransactionClass,
      closeTransactionModal,
      formatDate,
      onChangePagination,
      getRecurrencyIcon,
      getRecurrencyTooltip,
    };
  },
};
</script>

<style lang="scss" src="./TransactionsList.scss" />
