<template>
  <div class="toggle-switch form-group">
    <label :for="name">{{ label }}</label>
    <input
      v-model="state.modelValue"
      :id="name"
      class="switch switch--shadow"
      type="checkbox"
      @change="onChange"
    />
    <label class="switch-label" :for="name"></label>
  </div>
</template>

<script>
import { reactive, watch } from "vue";
export default {
  props: {
    label: String,
    value: Boolean,
    name: String
  },
  setup(props, { emit }) {
    const state = reactive({
      modelValue: !!props.value || false,
    });

    watch(
      () => props.value,
      (newValue) => {
        state.modelValue = newValue;
      }
    );

    const onChange = () => {
      emit("toggle", state.modelValue);
      emit("update:modelValue", state.modelValue);
    };

    return {
      state,
      onChange,
    };
  },
};
</script>

<style lang="scss" src="./ToggleSwitch.scss" />
