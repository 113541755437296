<template>
  <loader v-if="state.shouldShowLoader" />
  <div v-else class="data-table">
    <data-table-filters v-if="!hideFilters" :show-period-filter="showPeriodFilter"
      :show-search-filter="showSearchFilter" @period-updated="onChangePeriod"
      @textual-search-updated="onChangeTextualSearch" />
    <div v-if="listEmpty" class="data-table__empty">
      <img src="../../assets/images/illustrations/empty-list.webp" class="data-table__empty-img" />
      <p>Não existem dados para exibir</p>
    </div>
    <div v-else class="data-table__content">
      <table class="data-table__table">
        <thead id="order-list">
          <tr>
            <th v-for="(col, index) in columns" :class="getColClasses(col)" :key="index"
              @click="sortTable(col.key, col.sortable, col.sortableKey)">
              {{ col.name }}
              <fa-icon v-if="col.sortable && state.sortColumn === col.key" :icon="getSortIcon(col.key)" />
            </th>
          </tr>
        </thead>
        <tbody>
          <template v-if="slots.row">
            <tr v-for="(row, index) in state.dataList" :key="index">
              <slot name="row" :row="row" :index="index"></slot>
            </tr>
          </template>
          <template v-else>
            <tr v-for="row in state.dataList" :key="row">
              <td v-for="col in columns" :key="col.key">{{ row[col.key] }}</td>
            </tr>
          </template>
        </tbody>
      </table>
      <div class="data-table__footer" v-show="showPagination">
        <Pagination :page-number="state.selectedPage" :page-size="state.registersPerPage"
          :total-items="state.totalItems" @page-selected="onChangePageNumber"
          @page-quantity-updated="onChangePageQuantity" />
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, useSlots, onMounted, watch, computed } from "vue";
import { showErrorToast } from "@/services/toastService";
import Loader from "../Loader/Loader.vue";
import DataTableFilters from "./DataTableFilters.vue";
import Pagination from '../Pagination/Pagination.vue';

export default {
  components: { Loader, DataTableFilters, Pagination },
  props: {
    fetchAction: Function,
    columns: Object,
    showPeriodFilter: Boolean,
    showSearchFilter: Boolean,
    hideFilters: Boolean,
    data: Object,
    sortedBy: String,
    showPagination: {
      type: Boolean,
      default: true,
    },
    registerPerPageQty: Number,
    isLoading: {
      type: Boolean,
      default: true
    }
  },
  setup(props, { emit }) {
    const state = reactive({
      ascending: false,
      sortColumn: props.sortedBy,
      dataList: null,
      selectedPage: 1,
      registersPerPage: props.registerPerPageQty || 10,
      totalItems: null,
      periodRange: null,
      textualSearch: null,
      shouldShowLoader: props.isLoading,
    });

    onMounted(() => {
      if (props.data) {
        if (!props.data?.items && !props.data?.totalItems && props.data?.length) {
          state.dataList = props.data;
          return;
        }

        state.dataList = props.data.items
        state.totalItems = props.data.totalItems
      } else {
        getData();
      }
    })

    const slots = useSlots();

    const listEmpty = computed({
      get() {
        return !state.dataList?.length
      }
    })

    watch(() => props.isLoading,
      (value) => {
        state.shouldShowLoader = value;
      })

    watch(
      () => props.data,
      (data) => {
        if (!data?.items && !data?.totalItems && data?.length) {
          state.dataList = data;
        }

        state.dataList = data.items
        state.totalItems = data.totalItems
      }
    )

    const getData = () => {
      if (props.fetchAction) {
        props.fetchAction({
          PageNumber: state.selectedPage,
          PageSize: state.registersPerPage,
          Period: state.periodRange,
          TextualSearch: state.textualSearch
        })
          .then(onFetchSuccess)
          .catch(onFetchError);
      }
    };

    const onFetchSuccess = (data) => {
      state.dataList = data.items;
      state.totalItems = data.totalItems;
    }

    const onFetchError = () => {
      showErrorToast('Ocorreu um erro ao buscar os dados. Tente novamente.')
    }

    const onChangePageNumber = (page) => {
      state.selectedPage = page;
      onChangePagination();
      getData();
    };

    const onChangePageQuantity = (quantity) => {
      state.registersPerPage = quantity;
      state.selectedPage = 1;
      onChangePagination();
      getData();
    };

    const onChangePeriod = (period) => {
      state.periodRange = period;
      getData();
    };

    const onChangeTextualSearch = (text) => {
      state.textualSearch = text;
      getData();
    };

    const onChangePagination = () => {
      emit('change-pagination', {
        PageSize: state.registersPerPage,
        PageNumber: state.selectedPage
      })
    }

    const getSortIcon = (colName) => {
      if (state.sortColumn === colName && state.ascending) {
        return "solid fa-chevron-up";
      }

      if (state.sortColumn === colName && !state.ascending) {
        return "solid fa-chevron-down";
      }
    };

    const sortTable = (col, sortable, sortableKey) => {
      if (!state.dataList || !sortable) {
        return;
      }

      if (state.sortColumn === col) {
        state.ascending = !state.ascending;
      } else {
        state.ascending = true;
        state.sortColumn = col;
      }

      let sortableItem = col;
      if (sortableKey) {
        sortableItem = `${col}.${sortableKey}`
      }

      state.dataList.sort((a, b) => {
        const aValue = getNestedValue(a, sortableItem);
        const bValue = getNestedValue(b, sortableItem);

        if (aValue > bValue) {
          return state.ascending ? 1 : -1;
        } else if (aValue < bValue) {
          return state.ascending ? -1 : 1;
        }
        return 0;
      });
    };

    const getNestedValue = (obj, path) => {
      return path.split('.').reduce((acc, part) => acc && acc[part], obj);
    };

    const getColClasses = (col) => {
      const cssClass = col.classes ? col.classes : ''
      return `${cssClass} ${col.sortable ? 'sortable' : ''}`
    }

    return {
      state,
      listEmpty,
      slots,
      sortTable,
      getSortIcon,
      onChangePageNumber,
      onChangePageQuantity,
      onChangePeriod,
      onChangeTextualSearch,
      getColClasses,
    };
  },
};
</script>

<style lang="scss" src="./DataTable.scss" />
