<template>
    <div class="unregister-marketing-view">
        <Loader v-if="state.isLoading" />

        <div v-if="state.isSuccess && !state.isLoading" class="unregister-marketing-view__success">
            <fa-icon class="unregister-marketing-view__icon" icon="fa-solid fa-circle-check" />
            <h1 class="unregister-marketing-view__title">Sentiremos sua falta :(</h1>
            <p class="unregister-marketing-view__description">Seu e-mail foi descadastrado com sucesso.</p>
            <p class="unregister-marketing-view__description">Para receber novamente nossos e-mails, acesse sua conta e ative as notificações via e-mail.</p>
        </div>

        <div v-if="!state.isSuccess && !state.isLoading" class="unregister-marketing-view__error">
            <fa-icon class="unregister-marketing-view__icon unregister-marketing-view__icon--error" icon="fa-solid fa-circle-xmark" />
            <h1 class="unregister-marketing-view__title">Não foi possível descadastrar</h1>
            <p class="unregister-marketing-view__description">Ocorreu um erro inesperado ao descadastrar seu e-mail. Por favor, tente novamente mais tarde.</p>
        </div>

        <div v-if="!state.isLoading" class="unregister-marketing-view__footer">
            <button class="btn button-primary" @click="goToLogin">Acessar Calculando Riquezas</button>
        </div>
    </div>
</template>

<script>
import { onMounted, reactive } from 'vue';
import { unregisterMarketing } from '../services/userPreferencesService';
import { useRouter } from "vue-router";
import Loader from '@/components/Loader/Loader.vue';

export default {
    components: {
        Loader
    },
    setup() {
        const router = useRouter();
        const state = reactive({
            isLoading: true,
            isSuccess: false,
        })

        onMounted(() => {
            const userHash = router.currentRoute.value?.params?.hash;

            unregisterMarketing(userHash).then(() => {
                state.isSuccess = true;
                state.isLoading = false;
            }).catch(() => {
                state.isLoading = false;
            });
        });

        const goToLogin = () => {
            router.push("/login");
        };

        return {
            state,
            goToLogin,
        };
    }
};
</script>

<style lang="scss" src="./styles/UnregisterMarketingView.scss" />