<template>
  <div class="card-resume" :class="type">
    <div class="card-resume__header">
        <div v-if="icon" class="card-resume__icon-box">
            <fa-icon class="card-resume__icon" :icon="state.iconName" />
        </div>
        <p class="card-resume__title">{{ title }}</p>
    </div>
    <div class="card-resume__content">
        <p class="card-resume__value">
            <toggle-currency-visibility :value="value" />
        </p>
        <slot name="default"></slot>
    </div>
    <router-link v-if="redirectPath" :to="redirectPath" class="card-resume__footer">
        Ir para {{ titleLower }}
        <fa-icon icon="fa-solid fa-arrow-right" />
    </router-link>
  </div>
</template>

<script>
import { reactive, computed } from 'vue';
import ToggleCurrencyVisibility from '@/modules/common/components/ToggleCurrencyVisibility/ToggleCurrencyVisibility.vue';

export default {
    components: { ToggleCurrencyVisibility },
    props: {
        title: String,
        icon: String,
        type: String,
        value: Number,
        buttonText: String,
        redirectPath: String,
    },
    setup(props) {
        const state = reactive({
            iconName: `fa-solid ${props.icon}`
        })

        const titleLower = computed({
            get() {
                return props.buttonText || props.title?.toLowerCase()
            }
        })

        return {
            state,
            titleLower,
        }
    }
}
</script>

<style lang="scss" src="./CardResume.scss" />
