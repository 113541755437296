<template>
    <div class="transaction-row-mobile" @click="toggleActions">
        <div class="transaction-row-mobile__infos">
            <div class="transaction-row-mobile__data">
                {{ transaction.description }}
                <br>
                <span class="transaction-row-mobile__category">
                    {{ transaction.category.name }}
                </span>
            </div>
            <div class="transaction-row-mobile__recurrence">
                <fa-icon class="transaction-row-mobile__recurrence-icon" v-if="getRecurrencyIcon(transaction)"
                    :icon="getRecurrencyIcon(transaction)" v-tooltip="getRecurrencyTooltip(transaction)" @click.stop />
            </div>
            <div class="transaction-row-mobile__data" :class="getTransactionClass(transaction.type)">
                <toggle-currency-visibility :value="Number(transaction.value)" />
                <br>
                <transaction-situation-badge class="transaction-row-mobile__situation" :type="transaction.type"
                    :situation="transaction.wasPaid" />
            </div>
        </div>
        <div v-if="state.shouldShowActions" class="transaction-row-mobile__actions">
            <button class="btn button-primary small" @click.stop="openEditTransaction">
                Editar
            </button>
            <button v-if="!transaction.wasPaid" class="btn button-primary button-primary--success small"
                @click.stop="openChangeTransactionSituationModal">
                {{ actionSituationLabel }}
            </button>
            <button class="btn button-primary button-primary--alert small transaction-row-mobile__remove"
                @click.stop="openRemoveTransactionModal">
                Excluir
            </button>
        </div>
    </div>
</template>

<script>
import { reactive, computed } from "vue";
import { useModal } from "vue-final-modal";
import { TRANSACTION_TYPES } from "../../constants/transactionConstants";
import { formatFullDate } from "@/services/formatService";
import { eventBus } from "@/services/eventBusService";
import { getRecurrenceIcon, getRecurrenceTooltip } from "../../services/transactionsService";
import RemoveTransactionModal from "../RemoveTransactionModal/RemoveTransactionModal.vue";
import CreateTransactionModal from '../CreateTransactionModal/CreateTransactionModal.vue';
import TransactionSituationBadge from '../TransactionSituationBadge/TransactionSituationBadge.vue';
import ToggleCurrencyVisibility from "@/modules/common/components/ToggleCurrencyVisibility/ToggleCurrencyVisibility.vue";
import ChangeTransactionSituationModal from '../ChangeTransactionSituationModal/ChangeTransactionSituationModal.vue';
import CreateTransferModal from '../CreateTransferModal/CreateTransferModal.vue';

export default {
    components: { TransactionSituationBadge, ToggleCurrencyVisibility },
    props: {
        transaction: Object,
    },
    setup(props) {
        const state = reactive({
            shouldShowActions: false,
        });

        const toggleActions = () => {
            state.shouldShowActions = !state.shouldShowActions
        }

        const getTransactionClass = (type) => {
            if (type === TRANSACTION_TYPES.EXPENSE.code) {
                return 'expense'
            }

            if (type === TRANSACTION_TYPES.REVENUE.code) {
                return 'revenue'
            }

            return 'secondary'
        };

        const actionSituationLabel = computed({
            get() {
                return props.transaction.type === TRANSACTION_TYPES.REVENUE.code ? 'Receber' : 'Pagar'
            }
        })

        const { open: openRemoveTransactionModal, close: closeRemoveTransactionModal } = useModal({
            component: RemoveTransactionModal,
            attrs: {
                transactionId: computed(() => props.transaction.id),
                nameTransaction: computed(() => props.transaction.description),
                recurrenceType: computed(() => props.transaction.recurrenceType),
                buttonType: 'alert',
                onClose() {
                    closeRemoveTransactionModal()
                },
                onConfirm() {
                    closeRemoveTransactionModal()
                },
                onCancel() {
                    closeRemoveTransactionModal()
                },
                onSuccess() {
                    eventBus.emit('reloadData');
                }
            }
        });

        const { open: openTransactionModal, close: closeTransactionModal } = useModal({
            component: CreateTransactionModal,
            attrs: {
                title: 'Editar transação',
                transactionId: computed(() => props.transaction.id),
                onClose() {
                    closeTransactionModal()
                },
                onConfirm() {
                    closeTransactionModal()
                },
                onCancel() {
                    closeTransactionModal()
                },
                onSuccess() {
                    eventBus.emit('reloadData');
                }
            }
        });

        const { open: openTransferModal, close: closeTransferModal } = useModal({
            component: CreateTransferModal,
            attrs: {
                transactionId: computed(() => props.transaction.id),
                onClose() {
                    closeTransferModal()
                },
                onConfirm() {
                    closeTransferModal()
                },
                onCancel() {
                    closeTransferModal()
                },
                onSuccess() {
                    eventBus.emit('reloadData');
                }
            }
        });

        const openEditTransaction = () => {
            if (props.transaction.type === TRANSACTION_TYPES.TRANSFER.code) {
                openTransferModal();
                return;
            }

            openTransactionModal()
        }

        const { open: openChangeTransactionSituationModal, close: closeChangeTransactionSituationModal } = useModal({
            component: ChangeTransactionSituationModal,
            attrs: {
                id: computed(() => props.transaction.id),
                type: computed(() => props.transaction.type),
                onClose() {
                    closeChangeTransactionSituationModal()
                },
                onSuccess() {
                    eventBus.emit('reloadData');
                    closeChangeTransactionSituationModal();
                },
                onCancel() {
                    closeChangeTransactionSituationModal()
                }
            }
        });

        const formatDate = (date) => {
            return formatFullDate(new Date(date))
        }

        const getRecurrencyIcon = (transaction) => {
            return getRecurrenceIcon(transaction.recurrenceType)
        }

        const getRecurrencyTooltip = (transaction) => {
            return getRecurrenceTooltip(transaction)
        }

        return {
            state,
            actionSituationLabel,
            openRemoveTransactionModal,
            openEditTransaction,
            getTransactionClass,
            closeTransactionModal,
            formatDate,
            toggleActions,
            getRecurrencyIcon,
            getRecurrencyTooltip,
            openChangeTransactionSituationModal
        };
    },
};
</script>

<style lang="scss" src="./TransactionRowMobile.scss" />