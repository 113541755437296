export const MENU_SIZE_KEY = "MENU_SIZE";

export const MENU_ROUTES = Object.freeze([
    {
        title: "Visão Geral",
        href: "/",
        icon: "fa-house",
    },
    {
        title: "Planos",
        href: "/plans",
        icon: "fa-crown",
        class: 'plans'
    },
    {
        title: "Financeiro",
        href: "",
        icon: 'fa-dollar-sign',
        child: [
            {
                title: "Resumo",
                href: "/finance",
                icon: 'fa-dollar-sign',

            },
            {
                title: "Receitas",
                href: "/finance/revenues",
                icon: 'fa-arrow-right-to-bracket',
            },
            {
                title: "Despesas",
                href: "/finance/expenses",
                icon: 'fa-arrow-right-from-bracket',
            },
            {
                title: "Transferências",
                href: "/finance/transfers",
                icon: 'fa-arrow-right-arrow-left',
            },
            {
                title: "Planejamentos",
                href: "/finance/plannings",
                icon: 'fa-clipboard-list',
            },
            {
                title: "Objetivos",
                href: "/finance/goals",
                icon: 'fa-bullseye',
            },
            {
                title: "Cadastros",
                href: "",
                icon: 'fa-plus',
                child: [
                    {
                        title: "Categorias",
                        href: "/finance/categories",
                        icon: 'fa-chart-pie',
                    },
                    {
                        title: "Importar dados",
                        href: "/finance/import",
                        icon: 'fa-database',
                    },
                ],
            },
        ],
    },
])

