<template>
    <div class="user-preferences-view">
        <box-page v-if="state.isLoading">
            <loader />
        </box-page>
        <template v-else>
            <box-page title="Geral">
                <div class="user-preferences-view__option">
                    <div class="user-preferences-view__row">
                        <toggle-switch name="toggleCurrency" :value="state.preferences.account.showCurrency"
                            v-model="state.preferences.account.showCurrency" />
                        <span>Sempre exibir valores monetários</span>
                    </div>
                    <p class="user-preferences-view__option-description">Ao ativar essa opção, os valores monetários das
                        transações, planejamentos, objetivos sempre serão
                        exibidos.</p>
                </div>
            </box-page>
            <box-page title="Notificações">
                <div class="user-preferences-view__notify-channels">
                    <div class="user-preferences-view__option">
                        <div class="user-preferences-view__row">
                            <toggle-switch :value="state.preferences.notifications.sendEmail"
                                v-model="state.preferences.notifications.sendEmail" name="notifyEmail" />
                            <span>Receber notificações no e-mail</span>
                        </div>
                    </div>
                    <div class="user-preferences-view__option">
                        <div class="user-preferences-view__row">
                            <toggle-switch :value="state.preferences.notifications.sendPush"
                                v-model="state.preferences.notifications.sendPush" name="notifyPush"
                                @toggle="onTogglePushNotifications" />
                            <span>Receber notificações no navegador</span>
                        </div>
                        <p class="alert" v-if="state.showPushError">
                            As notificações estão desativadas no seu navegador. Por favor, ative-as nas configurações do
                            seu navegador para continuar.
                        </p>
                    </div>
                </div>
                <div class="user-preferences-view__options-header">
                    <h3>Tipos de notificações</h3>
                </div>
                <div class="user-preferences-view__options">
                    <div class="user-preferences-view__option">
                        <div class="user-preferences-view__row">
                            <toggle-switch :value="state.preferences.notifications.types.financialAlerts"
                                v-model="state.preferences.notifications.types.financialAlerts"
                                name="financialAlerts" />
                            <span>Alertas financeiros</span>
                        </div>
                        <p class="user-preferences-view__option-description">Lembretes sobre datas de vencimento,
                            gastos, transferências, pagamentos, desempenho, planejamento
                            mensal e objetivos.</p>
                    </div>
                    <div class="user-preferences-view__option">
                        <div class="user-preferences-view__row">
                            <toggle-switch :value="state.preferences.notifications.types.newsAlerts"
                                v-model="state.preferences.notifications.types.newsAlerts" name="newsAlerts" />
                            <span>Novidades e promoções</span>
                        </div>
                        <p class="user-preferences-view__option-description">
                            Novas funcionalidades e promoções para transformar sua vida financeira.
                        </p>
                    </div>
                    <div class="user-preferences-view__option">
                        <div class="user-preferences-view__row">
                            <toggle-switch :value="state.preferences.notifications.types.financialTipsAlerts"
                                v-model="state.preferences.notifications.types.financialTipsAlerts"
                                name="financialTipsAlerts" />
                            <span>Dicas financeiras</span>
                        </div>
                        <p class="user-preferences-view__option-description">
                            Sugestões práticas e personalizadas para cuidar melhor das suas finanças.
                        </p>
                    </div>
                </div>
            </box-page>
            <box-page class="user-preferences-view__footer">
                <request-button :fetch-action="saveAction" @fetch-success="onSaveSuccess" @fetch-error="onSaveError"
                    label="Salvar" />
            </box-page>
        </template>
    </div>
</template>

<script>
import { onMounted, reactive } from 'vue';
import { getPreferences, savePreferences } from '../services/userPreferencesService';
import { isSupported } from '@/modules/dashboard/services/notificationService';
import { showErrorToast, showSuccessToast } from '@/services/toastService';
import BoxPage from '@/components/BoxPage/BoxPage.vue';
import ToggleSwitch from '@/components/ToggleSwitch/ToggleSwitch.vue';
import RequestButton from '@/components/RequestButton/RequestButton.vue';
import Loader from '@/components/Loader/Loader.vue';

export default {
    components: { BoxPage, ToggleSwitch, RequestButton, Loader },
    setup() {
        const state = reactive({
            isLoading: true,
            showPushError: false,
            preferences: {
                account: {
                    showCurrency: false
                },
                notifications: {
                    sendEmail: false,
                    sendPush: false,
                    types: {
                        financialAlerts: false,
                        newsAlerts: false,
                        financialTipsAlerts: false
                    }
                }
            }
        })

        onMounted(() => {
            fetchAction();

            const permission = requestPermission()
            if (permission) {
                state.preferences.notifications.sendPush = true
            }
        })

        const fetchAction = () => {
            return getPreferences().then(onFetchSuccess).catch(onFetchError);
        }

        const onFetchSuccess = (preferences) => {
            state.isLoading = false;
            state.preferences = {
                ...state.preferences,
                ...preferences
            }
        }

        const onFetchError = () => {
            state.isLoading = false;
        }

        const saveAction = () => {
            return savePreferences({ preferences: state.preferences })
        }

        const onSaveSuccess = (preferences) => {
            showSuccessToast('Preferências salvas com sucesso!');
            state.preferences = {
                ...state.preferences,
                ...preferences
            }
        }
        const onSaveError = () => {
            showErrorToast('Ocorreu um erro ao salvar as preferências. Tente novamente.')
        }

        const requestPermission = async () => {
            if (!isSupported()) {
                return false;
            }

            if (Notification.permission === "granted") {
                return true;
            }

            if (Notification.permission !== "granted") {
                const permission = await Notification.requestPermission();
                return permission === "granted";
            }

            return false;
        };

        const onTogglePushNotifications = async (value) => {
            if (value) {
                const permission = await requestPermission();
                if (!permission) {
                    state.showPushError = true;
                    state.preferences.notifications.sendPush = false;
                }
            }
        };

        return {
            state,
            saveAction,
            onSaveSuccess,
            onSaveError,
            onTogglePushNotifications
        }
    }
}
</script>

<style lang="scss" src="./styles/UserPreferencesView.scss" />
