import * as notificationResource from './notificationResource';
import { useNotificationStore } from '@/store/notificationData';

export async function getNotifications() {
    const { data } = await notificationResource.getNotifications();

    setNotifications(data?.items);
    return data;
}

export async function readAllNotifications() {
    const { data } = await notificationResource.readAllNotifications();

    setNotifications(data?.items);
    return data;
}

export async function cleanAllNotifications() {
    return notificationResource.cleanAllNotifications();
}

export async function saveNotificationUserToken(token) {
    setNotificationToken(token);

    const { data } = await notificationResource.saveNotificationUserToken(token);

    return data;
}

export async function deleteNotificationUserToken(token) {
    setNotificationToken(null);

    const { data } = await notificationResource.deleteNotificationUserToken(token);

    return data;
}

export function isSupported() {
    return "Notification" in window && "serviceWorker" in navigator && "PushManager" in window;
}

function setNotificationToken(token) {
    const notificationStore = useNotificationStore();
    notificationStore.setUserToken(token);
}

function setNotifications(notifications = []) {
    const notificationStore = useNotificationStore();
    notificationStore.setNotifications(notifications);
}
