<template>
  <loader v-if="state.isLoading" />
  <div v-else class="cards-resume cards-resume--complete">
    <CardResume title="Receitas" type="up" icon="fa-plus" redirect-path="/finance/revenues" :value="state.transactionsData.revenues"/>
    <CardResume title="Despesas" type="down" icon="fa-minus" redirect-path="/finance/expenses" :value="state.transactionsData.expenses"/>
    <CardResume title="Transferências" icon="fa-arrow-right-arrow-left" redirect-path="/finance/transfers" :value="state.transactionsData.transfers"/>
    <CardResume v-if="showBalanceButton" title="Balanço" type="total" icon="fa-equals" redirect-path="/finance" button-text="resumo" :value="state.transactionsData.monthlyBalance"/>
    <CardResume v-else title="Balanço" type="total" icon="fa-equals" :value="state.transactionsData.monthlyBalance"/>
  </div>
</template>

<script>
import { onMounted, onUnmounted, reactive, watch } from 'vue';
import { useTransactionsFiltersStore } from "@/store/transactionsFilters";
import { getTransactionsSimpleBalance } from "../../services/transactionsService";
import { eventBus } from "@/services/eventBusService";
import CardResume from "../CardResume/CardResume.vue";
import Loader from '@/components/Loader/Loader.vue';

export default {
  components: { CardResume, Loader },
  props: {
    filters: Object,
    showBalanceButton: {
      type: Boolean,
      default: true
    }
  },
  setup(props) {
    const filtersStore = useTransactionsFiltersStore();

    const state = reactive({
      isLoading: true,
      transactionsData: {
        revenues: null,
        expenses: null,
        transfers: null,
        monthlyBalance: null,
      }
    })
    
    const reloadDataHandler = () => {
      fetchData()
    }

    onMounted(() => {
      eventBus.on('reloadData', reloadDataHandler)
      fetchData()
    });

    onUnmounted(() => {
      eventBus.off('reloadData', reloadDataHandler)
    })

    const fetchData = () => {
      let filters = null;
      
      if(props.filters?.StartDate) {
        filters = props.filters
      } else {
        filters = filtersStore.getTransactionsFilters
      }

      state.isLoading = true

      getTransactionsSimpleBalance({
        ...filters,
        Type: null
      })
        .then(fetchDataSuccess)
        .catch(fetchDataError)
    }

    const fetchDataSuccess = (balance) => {
      const {revenues, pendingRevenues, pendingExpenses, expenses, pendingTransfers, transfers} = balance;
      state.transactionsData = { ...balance }
      state.transactionsData.revenues = pendingRevenues + revenues;
      state.transactionsData.expenses = pendingExpenses + expenses;
      state.transactionsData.transfers = pendingTransfers + transfers;
      state.isLoading = false;
    }

    const fetchDataError = () => {
      state.isLoading = false;
    }

    watch(() => filtersStore.getTransactionsFilters, () => fetchData(), { deep: true });
    watch(() => props.filters, () => fetchData(), { deep: true });

    return {
      state
    }
  }
};
</script>

<style lang="scss" src="./CardsResume.scss" />
