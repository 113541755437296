<template>
    <div class="notification-list">
        <notification v-for="notification in state.notifications" :notification-data="notification"
            :key="notification.id" />
    </div>
</template>

<script>
import { onMounted, reactive, watch } from 'vue';
import { useNotificationStore } from "@/store/notificationData";
import Notification from '../Notification/Notification.vue';
export default {
    components: { Notification },
    setup() {
        const notificationStore = useNotificationStore();
        const state = reactive({
            notifications: []
        })

        onMounted(() => {
            state.notifications = notificationStore.getNotifications;
        })

        watch(() => notificationStore.getNotifications, () => {
            state.notifications = notificationStore.getNotifications;
        })

        return {
            state
        }
    }
}
</script>

<style lang="scss" src="./NotificationList.scss" />
