<template>
    <div class="toggle-button">
        <button class="btn toggle-button__btn-left" :class="[isActiveClass('left'), { small: isSmall }]" @click.stop.prevent="toggleActive('left')">
            {{ leftLabel }}
        </button>
        <button class="btn toggle-button__btn-right" :class="[isActiveClass('right'), { small: isSmall }]" @click.stop.prevent="toggleActive('right')">
            {{ rightLabel }}
        </button>
    </div>
</template>

<script>
import { reactive, watch } from 'vue'

export default {
    props: {
        leftLabel: String,
        rightLabel: String,
        isSmall: Boolean,
        modelValue: Boolean
    },
    setup(props, { emit }) {
        const state = reactive({
            isActive: props.modelValue ? 'right' : 'left',
            previousState: props.modelValue ? 'right' : 'left',
        })

        watch(() => props.modelValue, (value) => {
            state.isActive = value ? 'right' : 'left'
            state.previousState =  value ? 'right' : 'left'
        })

        const toggleActive = (side) => {
            state.previousState = state.isActive;
            state.isActive = side;
            const isActiveRight = state.isActive === 'right';
            emit('update:modelValue', isActiveRight);
            emit('change', isActiveRight);
        }

        const isActiveClass = (side) => {
            return state.isActive === side ? 'button-primary' : 'button-outline';
        }

        const revertState = () => {
            state.isActive = state.previousState;
        };

        return {
            state,
            isActiveClass,
            toggleActive,
            revertState
        }
    }
}
</script>

<style lang="scss" src="./ToggleButton.scss" />
